<template>
  <section id="dashboard-ecommerce">
    
    <!--   <b-row class="match-height">
      <b-col md="12">
        <SynchronizedChart />
      </b-col>
    </b-row> -->
      <b-card
    class="text-center"
  >
  <!--<b-row>
    <b-col cols="6" md="6">
      <b-badge
         pill
        :variant="variantColorHeader('New')">
         {{'New'}}
      </b-badge>
      {{newCount}}
    </b-col>
    <b-col cols="6" md="6">
      <b-badge
         pill
        :variant="variantColorHeader('Preparing')">
         {{'Preparing'}}
      </b-badge>
      {{preparingCount}}
    </b-col>
  </b-row>-->
    <b-row>
    <b-col cols="12" md="12">
    <div class="d-flex align-items-center justify-content-end">
                <b-input-group
                  class="input-group-merge"
                >
                 <b-form-input
                     v-model="searchQuery"
                     placeholder="Search by Order Number"
                     @submit="getAllCustomerOrders"
                     @input="checkSearchQueryLength"
                     v-on:keyup.enter="getAllCustomerOrders"
                  />

                  <b-input-group-append is-text v-if="searchQuery != ''">
                    <feather-icon
                      class=""
                      :icon="passwordToggleIcon"
                      @click="clearSearch()"
                    />
                  </b-input-group-append>
                </b-input-group>
    </div>
    </b-col>
  </b-row>
      </b-card>
  <b-container>
    <b-row>
      <b-col cols="12" md="12" v-if="orders == ''">
    <p style="text-align:center">No orders found</p>
      </b-col>
    </b-row>
  </b-container>
    <b-row cols="1" cols-md="3">
      <b-col col no-gutters v-for="order in orders" :key="order._id">
  <b-card
    class="text-center"
  >
      <b-col cols="12" md="12" class="order_number_font">
      {{order.order_number}} &nbsp;
      </b-col>
      <b-col cols="8" md="8" class="order_number_font mx-auto">
      <b-badge
         pill
        :variant="variantColorHeader(order.order_status)">
         {{order.order_status}}
      </b-badge>
    </b-col>
      <b-col cols="12" md="12" class="body_font" style="font-weight:Bold">
      {{order.unit_number}} {{order.created_by}}
    </b-col>
    <b-col cols="12" md="12" class="date_font" v-if="order.order_status!='Completed'">
      {{order.delivery_date + ' ' + order.delivery_timeslot}}
    </b-col>
        <b-col cols="12" md="12" class="date_font" v-if="order.order_status=='Completed'">
      {{order.completion_date}}
    </b-col>
    <hr>
    <b-row v-for="i in order.items" :key="i._id" class="item_font">
    <b-col cols="9" md="9" :style="'text-align:left'">
      {{i.item_name}} [{{i.product_code}}]
    </b-col>
    <b-col cols="3" md="3" style="text-align:right">
      {{i.selected_quantity}}
    </b-col>
    </b-row>
    <hr>
    <b-col cols="12" md="12">
    <b-button @click="showSignature(order)" :variant="variantColorButton(order.order_status)" class="mt-auto" v-if="order.order_status!='Completed' && order.order_status=='Preparing'">
      <span>Confirm Order</span>
  </b-button>
    </b-col>
  </b-card>
      </b-col>
    </b-row>

      <b-modal
        header-bg-variant="info" 
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Confirm Order"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
      >
      <div>
        <b-row>
          <b-col md="12">Sign below to acknowledge receipt of the following items:</b-col>
        </b-row>
        <br>
          <b-row v-for="i in currentOrderItems.items" :key="i._id" class="larger_item_font">
          <b-col cols="10" md="10" style="text-align:left">
            {{i.item_name}} [{{i.product_code}}]
         </b-col>
          <b-col cols="2" md="2" style="text-align:right">
            {{i.selected_quantity}}
         </b-col>
          </b-row>
          <br>
          <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col cols="6" md="6">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col cols="6" md="6">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

    <!-- <b-row class="match-height">
      <b-col md="6">
        <ZoommableGraph />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,BCardSubTitle, BTable, BImg, BButton, BBadge, BContainer, BFormInput, BInputGroup, BInputGroupAppend  } from 'bootstrap-vue'

//import { getUserData } from '@/auth/utils'
import { GET_API,POST_API } from "../../store/actions.type"
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import moment from "moment-timezone";
import Bus from "../../event-bus";
import { ref, onUnmounted } from '@vue/composition-api'

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,VueApexCharts,BDropdown, BDropdownItem,flatPickr,BCardSubTitle, BTable, BImg, BButton, BBadge, BContainer, BFormInput, BInputGroup, BInputGroupAppend
  },
  data() {
    return {
      header: [],

      orders: [],

      currentOrderItems: [],

      openedSignModel:null,

      order_signature : {
        image:null,
        name:'',
      },
      searchQuery : '',

      newCount: 0,

      preparingCount: 0
    }
  },
  computed: {
    passwordToggleIcon() {
      return 'XIcon' 
    }
  },
  methods:{
      getAllCustomerOrders(){

      return this.$store.dispatch(POST_API, {
           data:{
            user: this.$store.getters.currentUser._id,
            name: this.$store.getters.currentUser.name,
            keyword: this.searchQuery
           },
           api: '/api/get-all-customer-orders'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data.docs;
                this.newCount = this.$store.getters.getResults.data.newCount;
                this.preparingCount = this.$store.getters.getResults.data.preparingCount;

                if (this.orders.length != data.length)

                {
                
                this.orders = data;     
                
                }
            }
        });
    },
    processOrderStatus(order){

      if (order.order_status == 'New')

      {

      var msg = 'Are you sure you want to start preparing order ' + order.order_number + ' ? ';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              
      })
      .then((result) => {

        if (result.isConfirmed != false)
        {
          this.hitProcessOrderStatusAPI(order);
        }
      })
      }
    },
    hitProcessOrderStatusAPI(order)
    {
      return this.$store.dispatch(POST_API, {
           data:{
            id: order._id,
            order_number: order.order_number,
            order_status: order.order_status,
            order_signature: this.order_signature != null ? this.order_signature : '',
           },
           api: '/api/process-order-status'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.errorAlertCF();
            } else {
              this.showDismissibleAlert = false;

              //var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

              document.location.reload();

              });
            }
        });
    },
    showSignature(order){
          this.currentOrderItems = order;
          this.$refs['signatureModel'].show();
    },
    hideSignature(){
      this.openedSignModel = null;
      this.currentOrderItems = [];
    },
    async saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{

        this.order_signature.image = data;
        this.order_signature.name = 'signature.png';
        await this.hitProcessOrderStatusAPI(this.currentOrderItems);
        this.$refs['signatureModel'].hide();
      }
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    variantColorHeader(status){
      if (status == 'New') {
        return 'danger';
      }
      else if (status == 'Preparing') {
        return 'warning'; 
      }else{
        return 'success';
      }
    },
      variantColorButton(status){
      if (status == 'New') {
        return 'warning';
      }
      else if (status == 'Preparing') {
        return 'info'; 
      }
      else if (status == 'Cancelled') {
        return 'dark';
      }else{
        return 'success';
      }
    },
    clearSearch() {
      this.searchQuery = '';
      this.getAllCustomerOrders();
    },
    checkSearchQueryLength() {
      if (this.searchQuery == '')
      {
        this.getAllCustomerOrders();
      }
    }
  },  
  mounted() {
    this.getAllCustomerOrders();
  },
}
</script>

<style lang="scss">
    @media (max-width :767px)
    {
    .order_number_font {
      font-size: 20px;
    }
    .body_font {
      font-size: 20px;
      line-height: 1;
    }
    .date_font {
      font-size: 16px;
      line-height: 1.5;
    }
    .item_font {
      font-size: 13px;
      line-height: 1.25;
      text-align: left !important;
      font-weight: bold;
    }
    .larger_item_font {
      font-size: 14px;
      line-height: 1.25;
      text-align: left !important;
      font-weight: bold;
    }
    }
    @media (min-width :768px)
    {
    .order_number_font {
      font-size: 20px;
    }
    .body_font {
      font-size: 16px;
    }
    .date_font {
      font-size: 16px;
    }
    .item_font {
      font-size: 13px;
      line-height: 1.5;
      font-weight: bold;
    }
    .larger_item_font {
      font-size: 16px;
      line-height: 1.5;
      font-weight: bold;
    }
    }
    @media (min-width :1450px)
    {
    .order_number_font {
      font-size: 24px;
    }
    .body_font {
      font-size: 20px;
    }
    .date_font {
      font-size: 20px;
    }
    .item_font {
      font-size: 18px;
      line-height: 1.5;
      font-weight: bold;
    }
    .larger_item_font {
      font-size: 20px;
      line-height: 1.5;
      font-weight: bold;
    }
    }
    @media (min-width :2000px)
    {
    .order_number_font {
      font-size: 28px;
    }
    .body_font {
      font-size: 24px;
    }
    .date_font {
      font-size: 24px;
    }
    .item_font {
      font-size: 22px;
      line-height: 1.5;
      font-weight: bold;
    }
    .larger_item_font {
      font-size: 24px;
      line-height: 1.5;
      font-weight: bold;
    }
    }
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
